<template>
  <div>
    <v-card flat elevation="0">
      <v-card-title>All Tender</v-card-title>
      <v-divider></v-divider>
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.document_no_`]="{ item }">
          <router-link :to="'/purchasing/tender/detail?id=' + item.id">{{ item.document_no_ }}</router-link>
        </template>

        <template v-slot:[`item.published`]="{ item }">
          <div class="text-center">
            <v-icon v-if="item.published" color="green">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="text-center">
            <app-document-status v-model="item.status"></app-document-status>
          </div>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Document No.", value: "document_no_", class: "text-no-wrap" },
          { text: "Document Date", value: "document_date", class: "text-no-wrap" },
          { text: "Document Type", value: "document_type", class: "text-no-wrap" },
          { text: "Source Doc.", value: "source_document", class: "text-no-wrap" },
          { text: "Published", value: "published", class: "text-no-wrap"},
          { text: "Max. Vendor", value: "max_vendor", class: "text-no-wrap" },
          { text: "Due Date", value: "due_date", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
        ],
        data: [],
      },
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      const uri = "tender/data";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;
      this.datatable.loading = true;
      const params = {
        search: this.search,
        sort_by: sortBy,
        sort_desc: sortDesc,
        page: page,
        limit: itemsPerPage,
      }
      this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
