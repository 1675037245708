<template>
  <div>
    <v-container>
      <page-title title="Tender">
        <template slot="action">
          <v-btn color="primary" @click="dialog = true">Add</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>All</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="450px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Create New</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select v-model="form_data.document_type" :error="form_error.document_type" outlined :items="types"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="createDocument()">Create</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
export default {
  components: {
    ListData,
  },
  data() {
    return {
      tab: null,
      dialog: null,

      form_data: {
        type: '',
      },

      form_error: {
        type: null,
      },

      types: [
        '',
        'Project',
        'Non-Project'
      ]
    };
  },

  methods: {
    async createDocument() {
      this.showLoadingOverlay(true);
      var uri = '/tender/create'
      const formData = new FormData();
      formData.append("document_type", this.form_data.document_type);
      await this.$axios.post(uri, formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == 'success') {
            this.redirect('Purchasing.Tender.Edit', { id: res.data.data.tender.id });
            return;
          }
          this.showAlert(res.data.status, res.data.message);
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
          this.form_error = error.response.data.data.errors
        });
    }
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("tender", "view", true);
  },
};
</script>
